<template>
  <v-container class="tw-mb-10">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green"
        >
          Add it to the trip itinerary
        </h2>
        <p
          class="info-blurb tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-pb-4"
        >
          Finalize the details of this activity to add it to the crew’s
          itinerary.
        </p>
      </v-col>
    </v-row>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="save">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="activity.name"
              rules="required"
            >
              <j-text-field
                label="Name of Activity*"
                v-model="activity.name"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                placeholder="Cooking class, concert, dinner, hike, etc..."
                background-color="#fafafa"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <j-chip-group
            label="Event Type*"
            :items="activityTypes"
            :value="activity.type"
            @input="activity.type = $event"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="j-text-field-label">Date*</span>
            <v-expansion-panels
              v-model="isOpen"
              class="tw-rounded-md"
              ref="datePanel"
            >
              <v-expansion-panel ref="datesPanel">
                <v-expansion-panel-header>
                  <div
                    class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                  >
                    <v-icon>mdi-calendar-blank</v-icon>
                    <div
                      class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                    >
                      <span v-if="activity.startDate">{{
                        getFormattedDate(activity.startDate, "without-time")
                      }}</span>
                      <span v-else>Choose a date</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-date-picker
                    class="tw-py-4"
                    no-title
                    color="secondary"
                    v-model="activity.startDate"
                    :events="functionEvents"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="secondary"
                      @click="activity.startDate = null"
                      >Reset Date</v-btn
                    >
                  </v-date-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="activity.startTime"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="activity.startTime"
                type="time"
                :error-messages="errors"
                label="Start Time"
                class="tw-mt-2"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="activity.endTime"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="activity.endTime"
                type="time"
                :error-messages="errors"
                label="End Time"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Name of Place*"
              v-model="activity.nameOfPlace"
              placeholder="Where is the event located?"
              background-color="#fafafa"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="activity.linkUrl"
              rules="max:500"
            >
              <j-text-field
                label="Link"
                v-model="activity.linkUrl"
                placeholder="Paste the link where your crew can find out more"
                background-color="#fafafa"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-textarea
              v-model="activity.note"
              placeholder="Note any costs, things to bring, how to register, etc..."
              background-color="#fafafa"
              label="Note"
            />
          </v-col>
        </v-row>
        <span class="tw-hidden">{{ invalid }}</span>
      </v-form>
    </validation-observer>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { ACTIVITY_TYPES } from "@/enums/activities.js";

export default {
  name: "ActivityToItinerary",
  mixins: [FormattedDateRange],
  data() {
    return {
      activity: {
        name: "",
        description: "",
        text: "",
        type: null,
        nameOfPlace: "",
        linkUrl: "",
        note: "",
        tripId: this.$route.params.id,
        authorId: null,
        startDate: null,
        startTime: null,
        endTime: null,
        destinationId: null
      },
      timeOptions: [],
      isOpen: false,
      activityTypes: ACTIVITY_TYPES
    };
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },
  computed: {
    enable() {
      return (
        this.activity.nameOfPlace.length > 0 &&
        this.activity.startDate &&
        this.activity.type
      );
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    // getMinMaxDate(val) {
    //   if (val === "min") {
    //     if (!this.trip || !this.trip.startDate) return null;
    //     const inputDate = DateTime.fromISO(this.trip.startDate, {
    //       setZone: false
    //     });
    //     const resultDate = inputDate.minus({ days: 7 });
    //     return resultDate.toISODate();
    //   } else {
    //     if (!this.trip || !this.trip.endDate) return null;
    //     const inputDate = DateTime.fromISO(this.trip.endDate, {
    //       setZone: false
    //     });
    //     const resultDate = inputDate.plus({ days: 7 });
    //     return resultDate.toISODate();
    //   }
    // },
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    functionEvents(date) {
      let availableDates = [];
      if (this.trip.startDate && this.trip.endDate) {
        let startDate = DateTime.fromISO(this.trip.startDate, {
          setZone: true
        }).endOf("day");
        let endDate = DateTime.fromISO(this.trip.endDate, {
          setZone: true
        }).endOf("day");
        for (
          let date = startDate;
          date <= endDate;
          date = date.plus({ days: 1 })
        ) {
          availableDates.push(
            DateTime.fromISO(date, { setZone: true }).toISODate()
          );
        }
      }
      if (availableDates.includes(date)) return ["transparent"];
      return false;
    },
    async save() {
      this.setNavigationButton({ text: "Saving" });
      if (
        !this.trip ||
        !this.trip.destinations ||
        !this.trip.destinations.length > 0
      ) {
        this.$store.dispatch("meta/showGlobalAlert", {
          type: "error",
          text: "Error creating activity. First choose a destination!",
          timeout: 2000
        });
        return;
      }

      this.activity.destinationId = this.trip.destinations[0].id;
      try {
        let res = await this.$store.dispatch("activity/create", this.activity);
        setTimeout(() => {
          this.setNavigationButton({ text: "Save" });
          this.$router.push({
            name: "TripItineraryList",
            params: {
              id: this.$route.params.id,
              eventUuid: res.uuid
            }
          });
        }, 20);
      } catch (error) {
        this.$store.dispatch("meta/showGlobalAlert", {
          type: "error",
          text: "Error creating activity. Please try again.",
          timeout: 2000
        });
        console.error(error);
      }
    }
  },
  async beforeMount() {
    if (!this.trip) {
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation"
          }
        }
      ]);
    }
    if (this.$route.params.uuid) {
      const activity = await this.$store.dispatch("post-activity/find", {
        query: {
          uuid: this.$route.params.uuid
        }
      });
      if (activity && activity.data.length > 0) {
        this.activity = activity.data[0];
        this.activity.postActivityUUID = this.$route.params.uuid;
      }
    }

    if (this.$route.params.activeDate) {
      this.activity.startDate = DateTime.fromISO(
        this.$route.params.activeDate,
        {
          setZone: true
        }
      ).toISODate();
    } else {
      this.activity.startDate = DateTime.fromISO(this.trip.startDate, {
        setZone: true
      }).toISODate();
    }
    this.activity.authorId = this.$store.state.auth.user.id;

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Things to do",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: false,
      showBackButton: true
    });
    this.setNavigationButton({
      button: true,
      text: "Add to Itinerary",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  },
  destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
<style>
.v-date-picker-table--date .v-btn--text:has(.v-date-picker-table__events) {
  color: #00acf5;
}
</style>
